import React from 'react'
import { AppBar, Grid, Toolbar, Divider, Typography, List, ListItem } from '@material-ui/core'
import ktoLogo from '../../kto_header_logo.png'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import VersionDisplay from '../VersionDisplay'

const Header = (props) => {
    
    const navigationVisible = false;
    const matches = useMediaQuery('(min-width:750px)');
    
    if (matches) {
        return (
            <AppBar position='static'>
                <Toolbar height='300'>
        
                    <img src={ktoLogo} height="29" width ="121" alt="kto logo"/>
                    <Divider orientation='vertical' flexItem style={{ marginLeft: 20, marginRight: 20 }} />
                    <Typography variant='h4'>Status</Typography>
                    <Grid container justify='flex-end'>
                        <VersionDisplay version={props.version} />
                        {
                            navigationVisible &&
                                <List style={{ display: 'flex' }}>
                                    
                                    <ListItem onClick={props.handleClick} style={{padding:6}} >
                                    <Typography id="dashboard" >Dashboard</Typography>

                                    </ListItem>
                                    <ListItem onClick={props.handleClick} style={{padding:6}}>
                                        <Typography id="history">History</Typography>

                                    </ListItem>
    
                                </List>
                        }
                    </Grid>
                    
        
                </Toolbar>
        
            </AppBar>
        )
    } else {
        return(
            <AppBar position='sticky'>
                <Toolbar height='150'>
    
                    <img src={ktoLogo} height="20" width ="84" alt="kto logo"/>
                    <Divider orientation='vertical' flexItem style={{ marginLeft: 6, marginRight: 6 }} />
                    <Typography variant='h5'>Status</Typography>
                    <Grid container justify='flex-end'>
                    <VersionDisplay version={props.version} />
                    {
                        navigationVisible &&
                                <List style={{ display: 'flex' }}>
                                    <ListItem onClick={props.handleClick} style={{padding:6}} >
                                        <Typography id="dashboard" >Dashboard</Typography>

                                    </ListItem>
                                    <ListItem onClick={props.handleClick} style={{padding:6}}>
                                        <Typography id="history">History</Typography>

                                    </ListItem>
                                </List>
                    }
                    </Grid>

                          
                </Toolbar>
    
            </AppBar>
        )
       
    }   

}
export default Header