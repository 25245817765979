import axios from "axios";

import config from "../../../config.js";

function getktobetHealthCheckData() {
  
    return new Promise((resolve, reject) => {
      
      axios(config.ktobetHealthCheckAPI)
      .then(response => {
        const data = response.data
        const MAX_DISCREPANCY = 100
        const discrepancy = Math.abs(data.c.ab_conn - data.c.io_conn)
        const hasIssues = discrepancy >= MAX_DISCREPANCY
        

        resolve(hasIssues)
      }).catch(err => reject(err))
  
    })
  }

// function getktobetHealthCheckData() {
  
//   return new Promise((resolve, reject) => {
//     // throw new Error('error')
//     const data = {
//         "c": {
//           "io_conn": 230,
//           "ab_conn": 25
//         }
//       }
//       const MAX_DISCREPANCY = 100
//       const discrepancy = Math.abs(data.c.ab_conn - data.c.io_conn)
//       const hasIssues = discrepancy > MAX_DISCREPANCY

//       resolve(hasIssues)

//   })
// }

export default getktobetHealthCheckData;
