
const version = '1.1'

const pingdomAPI = {
  method: 'get',
  url: '/getpingdomdata',
  // url: 'api/3.1/checks',
  
  headers: {
    
    "Content-Type": "application/json",
    
  }

}
const ktobetHealthCheckAPI = {
  method: 'get',
  url: '/getktobethealthcheckdata',
  // url: 'https://www.kto.bet/api/healthcheck',
  
  
  headers: {
    
    "Content-Type": "application/json",
    
  }

}


const config = {

  pingdomAPI,
  ktobetHealthCheckAPI,
  version
  
}
export default config
