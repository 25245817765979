import React from 'react'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import BuildIcon from '@material-ui/icons/Build'
import FlagIcon from '@material-ui/icons/Flag'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'



const summaryStatuses = {

  NO_ISSUES: { message: 'The services are running fine.', icon: <CheckCircleIcon style={{color: 'green', fontSize: '170'}}>check</CheckCircleIcon> },
  MAINTENANCE: { message: 'Undergoing Maintenance', icon: <BuildIcon className='maintenance'>maintenance</BuildIcon> },
  NOTICE: { message: 'Some stuff is happening', icon: <FlagIcon style={{ color: 'blue', fontSize: '170' }}>notice</FlagIcon> },
  INCIDENT: { message: 'Incident in a service/services', icon: <WarningIcon style={{ color: '#f5fc0f', fontSize: '170' }}>incident</WarningIcon> },
  OUTAGE: { message: 'Outage in a service/services', icon: <ErrorIcon style={{ color: 'red', fontSize: '170' }}>outage</ErrorIcon> }
  
 
}



export default summaryStatuses
