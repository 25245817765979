
import React, { useState, useEffect } from 'react'

import { Typography, Paper, List, ListItem, Grid, Tooltip } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import icons from '../StatusIcons'


const TableLegend = () => {

  const [showMobileIcons, setShowMobileIcons] = useState(false)

  

  const matches = useMediaQuery('(min-width:750px)');
  
  useEffect( () => {
  
    if(matches){

      setShowMobileIcons(false)

    }else {
    
      setShowMobileIcons(true)
    
    }
  },[matches])

  
  
  return (
    <Paper variant='outlined' square style={{ backgroundColor: '#f4f5f0' }}>
      <Grid container>

        <Grid>
          <Typography variant='h6' style={{ padding: 10, marginRight: '10%', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Current Status by Service.</Typography>

        </Grid>

        <Grid>
          

          {!showMobileIcons &&
            <List style={{display:'flex', whiteSpace:'nowrap'}}>
              {
              Object.values(icons).map(
                ({ title, icon }, index) => 
                
                 <ListItem key={index} > {title} {icon} </ListItem>

                 
                 
              )
              }
            </List>
          }
          {showMobileIcons &&
            <List style={{display:'flex', padding:'5'}}>

              {Object.values(icons).map(
                ({ title, icon }, index) => 
                <Tooltip key={index}
                        title={title}
                        enterTouchDelay={100}
                        disableFocusListener>

                  <ListItem key={index} > {icon} </ListItem>

                </Tooltip> 
                  
              )
              }
              </List>
            
            }

          
        </Grid>
      </Grid>

    </Paper>

  )

    
  
}

export default TableLegend
