
import React, { Component } from 'react'

class History extends Component {
  render () {
    return <h3>History</h3>
  }
}

export default History
