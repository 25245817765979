import React from 'react'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import BuildIcon from '@material-ui/icons/Build'
import FlagIcon from '@material-ui/icons/Flag'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'

const icons = {

  NO_ISSUES: { title: 'No issues', icon: <CheckCircleIcon style={{ color: 'green', fontSize: '20' }}>No issues</CheckCircleIcon> },
  MAINTENANCE: { title: 'Maintenance', icon: <BuildIcon style={{ color: '#5d98d4', fontSize: '20' }}>maintenance</BuildIcon> },
  NOTICE: { title: 'Notice', icon: <FlagIcon style={{ color: 'blue', fontSize: '20' }}>notice</FlagIcon> },
  INCIDENT: { title: 'Incident', icon: <WarningIcon style={{ color: '#f5fc0f', fontSize: '20' }}>incident</WarningIcon> },
  OUTAGE: { title: 'Outage', icon: <ErrorIcon style={{ color: 'red', fontSize: '20' }}>outage</ErrorIcon> }

}

export default icons
