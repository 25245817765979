import React  from 'react'
import { Grid, Typography } from '@material-ui/core'
import summaryStatuses  from './summaryStatuses.js'
import useMediaQuery from '@material-ui/core/useMediaQuery'





const StatusSummary = (props) => {
  
  const matches = useMediaQuery('(min-width:750px)');
    
    let message, icon

    const incident = props.servicesHealthStatus.filter(service => service.hasIncident === true).length >= 1

    if (incident) {
      message = summaryStatuses.INCIDENT.message
      icon = summaryStatuses.INCIDENT.icon
    } else {
      message = summaryStatuses.NO_ISSUES.message
      icon = summaryStatuses.NO_ISSUES.icon
    }
    if(props.brand === 'kto.bet'){
      if(props.ktobetSocketsCheckHasIssues.hasIssues){
        message = summaryStatuses.INCIDENT.message
        icon = summaryStatuses.INCIDENT.icon
      }
    }
    let typographyVariant;
    if(matches) {

      typographyVariant="h4"      
    } else {

      typographyVariant="h5"
    }
    return (
      <Grid
        container
        
        alignItems='center'
        direction='column'
        style={{ marginBottom: 100, marginTop: 150 }}
      >

        <Grid item style={{marginBottom:30}}>         
            {
              icon
            }         
        </Grid>

        <Grid item>
          <Typography variant={typographyVariant}>
            {
              message
            }
          </Typography>
        </Grid>

      </Grid>
      )

     
  
}
export default StatusSummary
