import React from 'react'
import { Typography } from "@material-ui/core"

const VersionDisplay = (props) => {
    return (
        
                <Typography variant="body2" style={{color:"black"}}>
                     Version: {props.version}
                </Typography>
            
    )
}
export default VersionDisplay