import React, { Component } from "react";

import Dashboard from "./components/Dashboard";
import History from "./components/History";
import Header from "./components/Header";
import config from './config.js'

import { Button } from "@material-ui/core";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMainContent: <Dashboard />,
      brand: 'kto.com'
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    if (e.target.id === "dashboard") {
      if (this.state.currentMainContent.type.name === "Dashboard") {
        return;
      } else {
        this.setState({ currentMainContent: <Dashboard /> });
      }
    } else if (e.target.id === "history") {
      if (this.state.currentMainContent.type.name === "History") {
        return;
      } else {
        this.setState({ currentMainContent: <History /> });
      }
    }
  };
  componentDidMount() {
     this.setState(
       { brand: window.location.href.toLowerCase().includes("status.kto.com") ? 'kto.com' : 'kto.bet' }
       )
    
  }

  render() {
    let icon = document.getElementById('icon')

    if(this.state.brand === 'kto.com'){

      icon.href = "com_logo.png"
    } else {

      icon.href = "bet_logo.svg"
    }
    return (
      <div>
        <Header handleClick={this.handleClick} version={config.version}/>
        <Dashboard  brand={this.state.brand} />

      </div>
    );
  }
}

export default App;
