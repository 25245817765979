import React, { Component } from 'react'

import { Grid } from '@material-ui/core'

import TableLegend from '../TableLegend'
import ServiceAccessStatus from '../ServiceAccessStatus'

class StatusTable extends Component {
  render () {
    return (

      <Grid container direction='column' alignContent='center'>
        <Grid item xs={10}>

          <TableLegend />

        </Grid>
        { this.props.brand === 'kto.com' &&
            this.props.servicesHealthStatus.map(
              ({ name, hasIncident, lastErrorDateTime }, index) =>
                <Grid key={index} item xs={10}>
                  
                  <ServiceAccessStatus
                    serviceName={name}
                    hasIncident={hasIncident}
                    lastErrorDateTime={lastErrorDateTime}
                  />

                </Grid>

            )
          }
          { this.props.brand === 'kto.bet' &&
              
            <Grid item xs={10}> 
              <ServiceAccessStatus
                serviceName={'KTO.bet'}
                hasIncident={this.props.servicesHealthStatus[0].hasIncident}
                lastErrorDateTime={this.props.servicesHealthStatus[0].lastErrorDateTime}
              />
            </Grid>
           }
          { 
          
          this.props.brand === 'kto.bet' &&
              <Grid item xs={10}>
                <ServiceAccessStatus
                  serviceName={'KTO.bet EveryMatrix Connectivity'}
                  ktobetSocketsCheckHasIssues={this.props.ktobetSocketsCheckHasIssues} 
                />

              </Grid>
              
          }
      </Grid>

    )
  }
}
export default StatusTable
