import React, { Component } from "react";

import { Typography, Paper } from "@material-ui/core";
import icons from "../StatusIcons";

class ServiceAccessStatus extends Component {
  

  render() {
    let title, icon, message;

    if (this.props.hasIncident) {
      title = icons.INCIDENT.title;

      icon = icons.INCIDENT.icon;

      message = "Last error's time and date: " + this.props.lastErrorDateTime;
    } else {
      title = icons.NO_ISSUES.title;

      icon = icons.NO_ISSUES.icon;

      message = "";
    }
    if (this.props.ktobetSocketsCheckHasIssues !== undefined) {
      if (this.props.ktobetSocketsCheckHasIssues.hasIssues) {
        message =
          "We are experiencing some issues with EveryMatrix connectivity";

        title = icons.INCIDENT.title;

        icon = icons.INCIDENT.icon;
      }

      if (this.props.ktobetSocketsCheckHasIssues.errorFetching) {
        message = "Error has occurred when fetching the data";

        title = icons.INCIDENT.title;

        icon = icons.INCIDENT.icon;
      }
    }
    return (
      <Paper variant="outlined" square style={{ minHeight: 60 }}>
        <Typography style={{ padding: 10, fontWeight: "bold" }}>
          {this.props.serviceName}
        </Typography>
        <div style={{ paddingLeft: 10, paddingBottom: 10 }}>
          {title}
          {icon}
        </div>

        <Typography style={{ padding: 10 }}>{message}</Typography>
      </Paper>
    );
  }
}
export default ServiceAccessStatus;
