import React, { Component } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import StatusSummary from '../StatusSummary'
import StatusTable from '../StatusTable'
import getData from '../Data'
import getktobetHealthCheckData from '../Data/ktobetHealthCheck'

import { Grid } from '@material-ui/core'

class Dashboard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      servicesHealthStatus: [],
      ktobetSocketsCheckHasIssues: {
        hasIssues: false,
        errorFetching: false       
      },
      loading: true
    }
  }

  componentDidMount () {
    
    getData(this.props.brand)
      .then(data => this.setState(

        {
          servicesHealthStatus: data,
          loading: false
        }
      ))
      .catch(err => { 
        console.log(err)
        this.setState({ loading: true })

        })
    
      getktobetHealthCheckData()
      .then(check => this.setState(
        {
          ktobetSocketsCheckHasIssues: {
            hasIssues: check,
            errorFetching: false                  
          }
        }
        )).catch(err => {
          console.log(err)
          this.setState({ktobetSocketsCheckHasIssues: { hasIssues: true, errorFetching: true}})
       })
    
    this.timerID = setInterval(() => {
      

      getData(this.props.brand)
        .then(data => this.setState(
          {
            servicesHealthStatus: data,
            loading: false
          }
        ))
        .catch(err => {
          console.log(err)
          this.setState({ loading: true })
        })
        getktobetHealthCheckData()
        .then(check => this.setState(
          {
            ktobetSocketsCheckHasIssues: {
              hasIssues: check,
              errorFetching: false                  
            }
          }
        )).catch(err => {
          console.log(err)
          this.setState({ktobetSocketsCheckHasIssues: {hasIssues: true, errorFetching: true}})
        })
    },
    300000
    )
  }

  componentWillUnmount () {
    clearInterval(this.timerID)
  }

  render () {


    if (!this.state.loading) {
      return (

        <div>

          <StatusSummary
            servicesHealthStatus={this.state.servicesHealthStatus}
            ktobetSocketsCheckHasIssues={this.state.ktobetSocketsCheckHasIssues}
            brand={this.props.brand}
            />
          <StatusTable
            servicesHealthStatus={this.state.servicesHealthStatus}
            ktobetSocketsCheckHasIssues={this.state.ktobetSocketsCheckHasIssues}
            brand={this.props.brand}
            />
          
        </div>
      )
    } else {
      return (

        <Grid
          container
          direction='column'
          alignItems='center'
          style={{ marginTop: 250}}
        >
          <CircularProgress size={200}/>

        </Grid>

      )
    }
  }
}
export default Dashboard
