import axios from "axios";

import config from "../../config.js";


function getData(brand) {
  
  return new Promise((resolve, reject) => {
    let services = [];
    const currentDate = Date.now();
    
    
    axios(config.pingdomAPI).then((response) => {
      if (response.data.checks === undefined) {
        reject(new Error("Error, data undefined"));
      } else if (response.status !== 200) {
        reject(new Error("response is not 200"));
      } else {
        
        response.data.checks
          .filter(
            (service) =>
              service.name.toUpperCase().startsWith(brand.toUpperCase())
          )
          .forEach((service) =>
            services.push({
              name: service.name,
              hasIncident:
                currentDate - service.lasterrortime * 1000 < 3600000
                  ? true
                  : false,
              lastErrorDateTime: new Date(service.lasterrortime * 1000),
            })
          );
        resolve(services);
      }
    }).catch(err => reject(err));
  });
}

export default getData;
